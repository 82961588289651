/* Services section */
.services_title {
  color: var(--thirdColor);
  margin-top: 2cm;
  margin-bottom: 2cm;
}
.services_sections {
  margin-bottom: var(--sectionMargin);
}
.services_sections a {
  text-decoration: none;
}

.services_card {
  box-shadow: 0 1px 3px 0 rgba(66, 124, 157, 2.2),
    0 2px 20px 0 rgba(66, 124, 157, 2.2);

  border-radius: 0.3cm;
  transition: transform 0.8s;
}

.services_card:hover {
  transform: scale(1, 1.09);
}

.services_card_img {
  background-color: var(--secondColor);
  border-radius: 0.3cm 0.3cm 0 0;

  padding-top: 1cm;
  padding-bottom: 1cm;
}

.services_card_title {
  padding-top: var(--sectionMargin);
  padding-bottom: var(--sectionMargin);
  color: var(--thirdColor);
}

.services_card_img img {
  width: 50%;
}

/* Fondamentaux section */

.fondamentaux {
  color: var(--firstColor);
  background-size: 100% 100%;

  margin-top: 2.5cm;
  margin-bottom: 2cm;
}
.fondamentaux_title {
  padding-top: 2cm;
  margin-bottom: 1.5cm;
}

.fondamentaux_body {
  margin-bottom: 1cm;
}
.fondamentaux_body_last {
  padding-bottom: 1cm;
}

/* Contact us */
.contact_us {
  margin-bottom: 2cm;
}
.contact_header {
  margin-top: 1cm;
  margin-bottom: 1cm;
  color: var(--thirdColor);
}
#name,
#email,
#phoone,
#societe,
#subject {
  width: 49.6%;
  border-style: solid;
  padding-top: 0.5cm;
  padding-bottom: 0.5cm;
  border-radius: 0.3cm;
  padding-left: 0.2cm;
  border-width: 1px;
  /* border-color: ; */
  /* outline: none; */
  /* border-width: 2px; */
  border-color: var(--secondColor) !important;
}
#subject {
  width: 99.5%;
}
.contact-form input {
  margin-bottom: 0.3cm;
}

#message {
  border-radius: 0.3cm;
  padding-top: 0.5cm;
  padding-left: 0.2cm;
  margin-bottom: 0.5cm;
  width: 100%;
  padding-bottom: 100px;

  border: solid 1px var(--secondColor);
  /* border-color: !important; */
}

#message,
#email,
#societe,
#phone,
#name:focus {
  outline-color: var(--thirdColor);
}

#send_message {
  background-color: var(--thirdColor);
  color: var(--firstColor);
  padding: 0.3cm 1cm;
  border-radius: 0.2cm;

  border: none;
}

/* Small devices */
@media screen and (max-width: 991px) {
  /* Services Section */
  .services_card_title h2 {
    font-size: 22px;
  }

  /* Fondamentaux Section */
  /* Contact us */

  #name,
  #email,
  #societe,
  #phone {
    width: 49.2%;
  }
}

/* Large devices  */
@media screen and (min-width: 991px) {
  /* Services Section */
  .services_card_title {
    padding-top: 1cm;
    padding-bottom: 1cm;
  }
  .services_card_title h2 {
    font-size: 32px;
  }

  /* Fondamentaux Section */

  /* Contact us */
}
