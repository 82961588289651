.web {
  color: var(--thirdColor);
  margin-bottom: 1cm;
}

.card {
  padding-top: 0.5cm;
  padding-bottom: 0.5cm;
  background-color: var(--firstColor);
}

.card-title {
  text-align: center;
}
.web-service-header {
  margin-top: 1cm;
  margin-bottom: 0.5cm;
}

.web-services {
  margin-bottom: 0.5cm;
}
.web-services .card {
  box-shadow: 0 1px 3px 0 rgba(66, 124, 157, 2.2);
  transition: transform 0.8s;
}
.web-services .card:hover {
  background-color: var(--thirdColor);
  color: var(--firstColor);
  transform: scale(1, 1.09);
}

/* Small Devices */

@media screen and (max-width: 991px) {
  .web-services .col {
    margin-bottom: 0.5cm;
  }
  .web-services {
    margin-bottom: 0cm;
  }
}
