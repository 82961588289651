:root {
  --firstColor: #fafdff;
  --secondColor: #427c9d;
  --thirdColor: #2d5b75;
  --sectionMargin: 1cm;
  --normalHeaderSize: 20px;

  box-sizing: border-box;
}
body {
  background-color: var(--firstColor);
  font-family: "Montserrat", sans-serif;
}

.App {
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100vh;
}

main {
  flex: 1;
}
