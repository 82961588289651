.software {
  color: var(--thirdColor);
}

.software-header {
  margin-top: 1cm;
  margin-bottom: 1cm;
}
.software-header h2 {
  font-weight: bold;
}

.software-services {
  text-align: center;
  margin-bottom: 0.5cm;
  color: var(--thirdColor);
}
.last {
  margin-bottom: 1cm;
}

.software-services .card {
  box-shadow: 0 1px 3px 0 rgba(66, 124, 157, 2.2);
  transition: transform 0.8s;
}

.software-services .card:hover {
  background-color: var(--thirdColor);
  color: var(--firstColor);
  transform: scale(1, 1.09);
}
.card {
  padding-top: 0.5cm;
  padding-bottom: 0.5cm;
  background-color: var(--firstColor);
}

/* Small devices */
@media screen and (max-width: 991px) {
  .software-services {
    margin-bottom: 0cm;
  }
  .software-services .col {
    margin-top: 0.5cm;
  }
  .last {
    margin-bottom: 1cm;
  }
}
