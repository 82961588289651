footer {
  background-color: var(--secondColor);
  color: var(--firstColor);
}

#footer_services {
  padding-top: var(--sectionMargin);
}

#footer_services li {
  list-style-type: none;
}
#footer_services a {
  text-decoration: none;
  color: var(--firstColor);
}
#footer_services a:hover {
  font-weight: bolder;
}

#social_channels {
  margin-top: var(--sectionMargin);
}

#social_channels img {
  width: 23px;
  margin-left: 20px;
}

#copy_right {
  margin-top: 0cm;
  margin-bottom: 0.5cm;
}
#copy_right p {
  font-weight: lighter;
}

.contact {
  margin-top: 0.5cm;
}
.contact p {
  display: inline-block;
  text-align: center;
  padding-right: 0.5cm;
  font-weight: bolder;
}
/* Small devices */
@media screen and (max-width: 991px) {
}

/* Large devices  */
@media screen and (min-width: 991px) {
  /* #footer_contact {
    margin-left: 66% !important;
  } */
}
