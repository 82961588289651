#sysiot_logo {
  width: 150px;
}
.menu {
  color: var(--firstColor);

  background-size: 100% 100%;
}

nav a {
  color: var(--firstColor) !important;
  font-size: 20px !important;
}

.dropdown-item {
  color: var(--thirdColor) !important;
}

.menu_header {
  margin-top: 1cm;
  padding-bottom: 1.5cm;
}
.menu h1 {
  font-weight: lighter;
}
#menu_para {
  font-weight: 1000 !important;
}

/* Small devices */
@media screen and (max-width: 991px) {
  .menu h1 {
    font-size: 28px;
  }
  .navbar-toggler {
    background-color: var(--firstColor) !important;
    border-radius: 0.2cm;
  }
  .dropdown-toggle:active {
    outline-color: none !important;
    border-color: none !important;
  }
}

/* Large devices  */
@media screen and (min-width: 991px) {
  .dropdown-menu a {
    font-size: 14.5px !important;
    color: var(--firstColor) !important;
  }

  .dropdown-menu {
    background-color: transparent !important;
    border: none !important;
  }
  .dropdown-menu a:hover {
    background-color: var(--firstColor) !important;
    border-radius: 0.2cm;
    color: var(--thirdColor) !important;
    outline-color: none !important;
    padding: none !important;
  }
  .navbar-collapse {
    margin-left: 71%;
  }
}
